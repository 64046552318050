<template>
  <tr :class="product.stop? 'bg-#E9D0CF' : isActive ? 'bg-#eef7e7' : 'bg-seryy/10'">
    <td class="rounded-l-2 pl-2.5 w-6.5 py-1">
      <!-- <div
        :class="isActive ? 'bg-firmennyy ring-firmennyy/20 shadow-[inset_0_3px_4px_0]' : 'bg-white ring-border shadow-[inset_0_2px_3px_0]'"
        class="flex items-center justify-center rounded-full size-4 ring-.75 ring-inset shadow-black/7 text-2 text-white"
      >
        <span class="i-custom:checkmark"></span>
      </div> -->
    </td>
    <td class="pl-2.5 w-28 py-1">
      <NuxtImg
        class="size-25 rounded-2 object-cover object-center"
        :src="product.img_path"
        alt=""
      />
    </td>
    <td class="text-tekstovyy text-4 font-500 leading-0.8 pl-5 py-1">{{ product.title }}</td>
    <td class="text-#455862 text-3.75 font-500 leading-0.8 w-20 pl-5 py-1">{{ product.subtitle }}</td>
    <td class="text-#455862 text-3.75 font-500 leading-0.8 w-10 pl-5 py-1">{{ product.size_title }}</td>
    <td class="text-#455862 text-3.75 font-500 leading-0.8 w-10 pl-5 py-1">
      <div class="flex focus-within:ring-1 focus-within:ring-seryy focus-within:bg-white/50 p-1.25">
        <div class="relative">
          <input
            ref="priceInput"
            v-model="price"
            class="appearance-none outline-none bg-transparent w-full absolute left-0 top-1/2 -translate-y-1/2 text-3.75 font-500 leading-0.8 -tracking-0.02em [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
            name="price"
            :disabled="!priceIsEdit"
            type="number"
            @keyup.enter="priceSave"
            @blur="blurHandler"
          />
          <p class="whitespace-nowrap invisible">{{ price }}</p>
        </div>
        ₽
      </div>
    </td>
    <td class="rounded-r-2 px-5 w-16">
      <Dropdown :ui="{ wrapper: 'ring-#f1f1f1 ring-inset ring-1 shadow-base shadow-black/8 bg-white p-2 flex flex-col absolute rounded-4.5 top-full -right-5 translate-y-1.25 z-1', root: 'relative' }">
        <template #dropdown-header-content>
          <span class="text-firmennyy i-custom:dots-three-outline-vertical text-6 block"></span>
        </template>
        <template
          #="{ isShowToggle }"
        >
          <div
            class="uno-3qtl1i"
            @click="editClickHandler(), isShowToggle()"
          >
            <span class="i-custom:pencil-simple text-6 transition-colors"></span>
            Редактировать цену
          </div>
          <div
            class="uno-3qtl1i"
            @click="emits('change-product-info')"
          >
            <span class="i-custom:pencil-simple text-6 transition-colors"></span>
            Редактировать информацию о продукте
          </div>
          <div
            v-if="!product.stop && type !== 'stopList'"
            class="uno-3qtl1i"
            @click="emits('openStopList')"
          >
            <span class="i-custom:x-circle text-6 transition-colors"></span>
            Убрать блюдо с сайта
          </div>
          <div
            v-else
            class="uno-3qtl1i"
            @click="handleDeleteFromStopList"
          >
            <span class="i-custom:arrow-u-up-left text-6 transition-colors"></span>

            Разместить блюдо на сайте
          </div>
        </template>
      </Dropdown>
    </td>
  </tr>
</template>

<script setup lang="ts">
import type { MenuProduct, StopListProductType } from '~/composables/partner/menu/types/MenuTypes';
import { useMenu } from '~/composables/partner/menu/useMenu';
const props = defineProps<{
	isActive: boolean,
	product: MenuProduct | StopListProductType,
	type?: 'category' | 'stopList'
}>();
const emits = defineEmits(['openStopList', 'update-list', 'change-product-info']);
const { changeMenuItemPrice, removeItemFromStopList } = useMenu();

const changePrice = async (price: number) => {
  const res = await changeMenuItemPrice({ price: price, product_id: props.product.id });
};

const priceInput = ref<HTMLInputElement>();

const priceIsEdit = ref(false);

const editClickHandler = async () => {
  priceIsEdit.value = true;
  priceEdited.value = false;
  await nextTick();
  priceInput.value?.focus();
};

const priceEdited = ref(false);

const price = ref(props.product.price);
const blurHandler = () => {
  if (!priceEdited.value) { price.value = props.product.price; }
  priceIsEdit.value = false;
};

const handleDeleteFromStopList = async () => {
  const res = await removeItemFromStopList({ product_id: props.product.id });
  if (res?.message === 'Success') {
    emits('update-list');
  }
};

const priceSave = () => {
  changePrice(price.value);
  priceEdited.value = true;
  priceIsEdit.value = false;
};
</script>
