<template>
  <div class="flex flex-col gap-3.5 rounded-4.5 bg-white ring-inset ring-1 ring-#f1f1f1 shadow-base shadow-black/8 px-6 py-5">
    <div class="flex gap-3 items-center">
      <template v-if="type === 'category'">
        <div class="flex items-center justify-center rounded-full size-6 ring-.75 ring-inset shadow-black/7 text-3 text-white bg-firmennyy ring-firmennyy/20 shadow-[inset_0_3px_4px_0]">
          <span class="i-custom:checkmark"></span>
        </div>
        <div class="flex flex-col gap-1">
          <p class="text-tekstovyy text-4.5 font-500 leading-0.8">{{ category_and_products?.category.title }}</p>
          <p class="text-seryy text-3 font-500 leading-0.8">Позиций: {{ category_and_products?.products.length }}</p>
        </div>
      </template>
      <template v-else>
        <p class="text-tekstovyy text-5.5 font-700">Стоп-лист</p>
      </template>
    </div>
    <div :class="{ 'pt-3.5 border-t border-#dee8ee': type === 'category' }">
      <table
        v-if="category_and_products?.products.length ?? 0 > 0"
        class="w-full border-spacing-y-1 border-separate border-spacing-0"
      >
        <tbody>
          <MenuCard
            v-for="item in category_and_products?.products"
            :key="item.id"
            :product="item"
            :is-active="false"
            :type
            @openStopList="openStopList(item.id)"
            @change-product-info="openChangeModal(item)"
            @update-list="$emit('update-list')"
          />
        </tbody>
      </table>
      <table
        v-if="stopListItems"
        class="w-full border-spacing-y-1 border-separate border-spacing-0"
      >
        <tbody>
          <MenuCard
            v-for="item in stopListItems"
            :key="item.id"
            :product="item"
            :is-active="false"
            :type
            @openStopList="openStopList(item.id)"
            @update-list="$emit('update-list')"
            @change-product-info="openChangeModal(item)"
          />
        </tbody>
      </table>
      <!-- <div
        v-else
        class="flex flex-col gap-3 items-center justify-center text-#ec6236 text-4.5 font-500 leading-0.8 h-150"
      >
        <img
          src="/imgs/stop-sign.png"
          alt=""
        >
        <p>В стоп-листе нет позиций</p>
      </div> -->
    </div>
    <TransitionModal>
      <LazyStopListModal
        v-if="isModalShow && stopListProductId"
        :product-id="stopListProductId"
        @close="closeModal"
        @update-list="$emit('update-list')"
      />
      <LazyChangeProductModal
        v-if="isModalShow && changeMenuItem"
        :product-id="changeMenuItem.id"
        :product="changeMenuItem"
        @close="closeModal"
        @update-list="$emit('update-list')"
      />
    </TransitionModal>
  </div>
</template>

<script setup lang="ts">
import type { MenuCategoryWithProducts, MenuProduct } from '~/composables/partner/menu/types/MenuTypes';
const props = defineProps<{ category_and_products?: MenuCategoryWithProducts, type: 'category' | 'stopList', stopListItems?: MenuProduct[] }>();
const { closeModal, isModalShow, openModal } = useModal();

const stopListProductId = ref<number | null>(null);
const changeMenuItem = ref<MenuProduct | null>(null);
defineEmits(['update-list']);
const openStopList = (id:number) => {
  stopListProductId.value = id;
  openModal();
};

const openChangeModal = (item: MenuProduct) => {
  changeMenuItem.value = item;
  openModal();
};
</script>
