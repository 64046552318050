import type { Ref } from 'vue';
import { defineStore } from 'pinia';
import type { StopListProductDetailsType, MenuCategoryWithProducts } from '~/composables/partner/menu/types/MenuTypes';

type menuStoreType = {
    itemsList: Ref<MenuCategoryWithProducts[] | null>,
    setItems: (menu: MenuCategoryWithProducts[]) => void,
    stopListItems: Ref<StopListProductDetailsType[] | null>,
    setStopList: (list: StopListProductDetailsType[]) => void
}

export const menuStore = defineStore('menu-store', (): menuStoreType => {
  const itemsList = ref<MenuCategoryWithProducts[] | null>(null);

  const setItems = (menu: MenuCategoryWithProducts[]) => {
    itemsList.value = menu;
  };

  const stopListItems = ref<StopListProductDetailsType[] | null>(null);

  const setStopList = (list: StopListProductDetailsType[]) => {
    stopListItems.value = list;
  };

  return {
    itemsList,
    setItems,
    stopListItems,
    setStopList
  };
});
