import type { ServerResponseCommonType } from '~/types/commons';
import type { RestaurantId } from '../orders/types/PartnerOrdersTypes';
import { storeToRefs, computed } from '#imports';
import { menuStore as store } from '~/stores/menu-store';
import type {
  AddItemInStopListRequestType,
  ChangeMenuPriceRequestType, MenuCategoryWithProducts, StopListProductDetailsType
} from './types/MenuTypes';

type TransportMenuDataType = {
    getMenu: (restraunt_id: RestaurantId) => Promise<MenuCategoryWithProducts[] | null>;
    menuItemsList: MenuCategoryWithProducts[] | null;
    changeMenuItemPrice: (body: ChangeMenuPriceRequestType) => Promise<ServerResponseCommonType | null>
    getStopList: (restaurant_id: RestaurantId) => Promise<StopListProductDetailsType[] | null>;
    putItemInStopList: (body: AddItemInStopListRequestType) => Promise<ServerResponseCommonType | null>,
    removeItemFromStopList: (body: {product_id: number}) => Promise<ServerResponseCommonType | null>
}

export function useMenu (): TransportMenuDataType {
  const { $api } = useNuxtApp();
  const menuStore = store();
  const { itemsList } = storeToRefs(menuStore);

  const getMenu = async (restraunt_id: RestaurantId): Promise<MenuCategoryWithProducts[] | null> => {
    const res = await $api<ServerResponseCommonType<MenuCategoryWithProducts[]>>(`/partner/menu/getMenu?restaurant_id=${restraunt_id.restaurant_id}`);
    if (res && res.data) {
      menuStore.setItems(res.data);
      return res.data;
    }
    return null;
  };

  const getStopList = async (restaurant_id: RestaurantId): Promise<StopListProductDetailsType[] | null> => {
    const res = await $api<ServerResponseCommonType<StopListProductDetailsType[]>>(
        `/partner/menu/getStopList?restaurant_id=${restaurant_id.restaurant_id}`);
    if (res && res.data) {
      menuStore.setStopList(res.data);
      return res.data;
    }

    return null;
  };

  const changeMenuItemPrice = async (body: ChangeMenuPriceRequestType): Promise<ServerResponseCommonType | null> => {
    const res = await $api<ServerResponseCommonType>('partner/menu/changeProduct', {
      method: 'POST',
      body: {
        ...body
      }
    });

    if (res && res.message === 'Success') {
      return res;
    }

    return null;
  };

  const putItemInStopList = async (body: AddItemInStopListRequestType): Promise<ServerResponseCommonType | null> => {
    const res = await $api<ServerResponseCommonType>('partner/menu/addProductToStopList', {
      method: 'POST',
      body: {
        ...body
      }
    });

    if (res && res.message === 'Success') {
      return res;
    }

    return null;
  };
  const removeItemFromStopList = async (body: AddItemInStopListRequestType): Promise<ServerResponseCommonType | null> => {
    const res = await $api<ServerResponseCommonType>('partner/menu/removeProductFromStopList', {
      method: 'POST',
      body: {
        ...body
      }
    });

    if (res && res.message === 'Success') {
      return res;
    }

    return null;
  };

  const menuItemsList = itemsList.value;

  return {
    menuItemsList,
    getMenu,
    changeMenuItemPrice,
    getStopList,
    putItemInStopList,
    removeItemFromStopList
  };
}
